<template>
  <v-container
    :class="`${$vuetify.breakpoint.mdAndUp ? 'px-6 pt-6' : 'px-2 pt-2'}`"
  >
    <v-card class="mx-auto elevation-0 pa-0" outlined id="newCardClients">
      <v-data-table
        :headers="headers"
        :items="pagingListClients"
        :items-per-page="itemsPerPage"
        :footer-props="{ itemsPerPageOptions: itemsOptions }"
        :options.sync="pagination"
        :server-items-length="count"
        :single-expand="true"
        :expanded.sync="newExpanded"
        :loading="loadingClients"
        :fixed-header="$vuetify.breakpoint.lgAndUp"
        :height="tableHeight"
        item-key="key"
        sort-by="created_at"
        class="fill-height ma-0"
        mobile-breakpoint="900"
        loading-text="Carregando clientes... Por favor, aguarde"
        no-results-text="Não foram encontrados resultados para a sua pesquisa"
        dense
      >
        <template v-slot:top>
          <!-- Título do card -->
          <div id="clientTableHeader">
            <slot name="header" />

            <SearchFilterBar
              label="Pesquisar por nome ou domínio"
              placeholder=""
              :applied-filters="appliedFiltersToDisplay"
              :loading="loadingClients"
              @search-by="updateFilters"
              @input-search="setSearch"
              @remove:filter="removeFilter"
              is-customer-table
              class="my-3"
            />

            <v-card-text v-if="$vuetify.breakpoint.smAndDown" class="py-0 px-3">
              <CustomerTableColsSelector
                @change:table-cols="changeColumnsView"
                :mobile-view="$vuetify.breakpoint.smAndDown"
              />
            </v-card-text>

            <v-divider />
          </div>
        </template>

        <!-- EMPRESA (nome e domínio) -->
        <template v-slot:[`item.name`]="{ item }">
          <v-list-item
            class="px-0 py-2"
            style="border: 0px !important"
            :ripple="false"
            selectable
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap break-word mb-1">
                <router-link
                  :class="`primary--text text--lighten-3 link font-weight-bold text-decoration-none`"
                  :to="{
                    path: '/reseller/client',
                    query: {
                      domain: item.main_domain,
                      key: item.key,
                    },
                  }"
                >
                  {{ item.name }}

                  <TooltipIcon
                    v-if="item.is_reseller && isConecta"
                    icon="mdi-cloud-check-variant"
                    color="success"
                    size="20"
                    label="Esta empresa é Conecta Partner"
                  />

                  <TooltipIcon
                    v-else-if="!item.cnpj"
                    icon="mdi-alert"
                    color="warning"
                    size="18"
                    label="Esta empresa possui pendências cadastrais: CNPJ ausente"
                  />
                </router-link>
              </v-list-item-title>
              <v-list-item-subtitle class="text-wrap break-word">
                {{ item.main_domain }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <!-- CNPJ DA EMPRESA -->
        <template v-slot:[`item.cnpj`]="{ item }">
          <span
            :class="`${
              !item.cnpj ? 'secondary--text text--lighten-2 text-body-2' : ''
            }`"
          >
            {{ maskCNPJ(item.cnpj) || "-" }}
          </span>
        </template>

        <!-- NÚMERO DE USUÁRIOS -->
        <template v-slot:[`item.users_number`]="{ item }">
          <div>{{ item.users_number }}</div>
        </template>

        <template v-slot:[`item.plan`]="{ item }">
          <CustomerPlan :company="item" />
        </template>

        <template v-slot:[`item.current_plan.subscription_type`]="{ item }">
          <CustomerPlanSubscription :company="item" />
        </template>

        <template v-slot:[`item.current_plan.end_trial`]="{ item }">
          <div v-if="item.current_plan">
            <span>
              {{ formatDateFullMonth(item.current_plan.end_trial) }}
            </span>
          </div>
        </template>

        <template v-slot:[`item.renovation`]="{ item }">
          <div v-if="item.current_plan">
            <span
              v-if="
                item.current_plan.subscription_type === SUBSCRIPTIONS.ANNUAL
              "
            >
              {{ formatDateFullMonth(item.current_plan.next_billing) }}
            </span>
            <span v-else>
              {{
                formatDateFullMonth(addYears(item.current_plan.init_date, 1))
              }}
            </span>
          </div>
        </template>

        <!-- DATA DE CRIAÇÃO DA EMPRESA -->
        <template v-slot:[`item.created_at`]="{ item }" clas="orange">
          {{ formatDateFullMonth(item.created_at) }}
        </template>

        <!-- DATA DE CANCELAMENTO DA EMPRESA -->
        <template v-slot:[`item.cancellation_date`]="{ item }">{{
          formatDateFullMonth(item.cancellation_date) || "-"
        }}</template>

        <!-- INFORMAÇÕES SE RESELLER -->
        <template v-slot:[`item.reseller_name`]="{ item }">
          {{ item.reseller_name ? item.reseller_name : "-" }}
        </template>

        <!-- ÚLTIMA VISUALIZAÇÃO -->
        <template v-slot:[`item.last_seen`]="{ item }">{{
          item.last_seen ? formatDateAndHour(item.last_seen) : ""
        }}</template>

        <!-- RESPONSÁVEL -->
        <template v-slot:[`item.responsible_email`]="{ item }">
          <UserBadge
            :user="{
              name: item.responsible_name,
              email: item.responsible_email,
            }"
            hide-image
            show-user-email
            max-width="200px"
          />
        </template>

        <!-- TODOS OS DOMINIOS -->
        <template v-slot:[`item.domains_list`]="{ item }">
          <v-chip
            small
            class="ma-2"
            v-for="domain in item.domains_list"
            :key="domain"
          >
            {{ domain }}
          </v-chip>
        </template>

        <!-- INFORMAÇÕES DO PARCEIRO RESELLER -->
        <template v-slot:[`item.reseller_company`]="{ item }">
          <v-list-item class="pa-0" v-if="item.reseller_company.name">
            <v-list-item-content>
              <v-list-item-title>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="blue--text text--darken-2 text-link font-weight-medium"
                      @click="goToReseller(item.reseller_company.domain)"
                    >
                      {{
                        item.reseller_company ? item.reseller_company.name : "-"
                      }}
                    </span>
                  </template>
                  <span>Clique para acessar detalhes deste parceiro</span>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle>
                Tipo de parceria:
                {{
                  item.reseller_company
                    ? translateResellerTypes[item.reseller_company.type]
                    : "-"
                }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Desconto:
                {{
                  item.reseller_company
                    ? `${item.reseller_company.discount * 100} %`
                    : "-"
                }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Comissão:
                {{
                  item.reseller_company
                    ? `${item.reseller_company.commission * 100} %`
                    : "-"
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <span v-else> - </span>
        </template>

        <!-- INFORMAÇÕES DO CONTRATO -->
        <template v-slot:[`item.contract`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                small
                v-bind="attrs"
                v-on="on"
                :color="item.contract ? 'success' : 'warning'"
              >
                {{ item.contract ? "Aceito" : "Pendente" }}
              </v-chip>
            </template>
            <span>
              {{
                item.contract
                  ? `Aceito em ${
                      item.contract.datetime
                        ? formatDateAndHour(item.contract.datetime)
                        : formatDateAndHour(item.contract_date)
                    } por ${item.contract.user.name}`
                  : "O cliente ainda não aceitou os termos"
              }}
            </span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <label v-if="item.status === 'ACTIVE'">Ativo</label>
          <label v-else-if="item.plans.at(-1).status === 'DISABLED'">
            Inativa
          </label>
          <label v-else-if="item.plans.at(-1).status === 'TRIAL'">
            Trial acaba em {{ formatDateFullMonth(item.end_trial_at) }}
          </label>
        </template>

        <template v-slot:[`item.trial`]="{ item }">
          <div
            :class="`word-break ${activeOrTrialPlan.color} ${
              activeOrTrialPlan.color && 'font-weight-medium'
            } my-1`"
            v-for="(activeOrTrialPlan, i) in getPlansInTrialAndActive(
              item.plans
            )"
            :key="i"
          >
            {{ `${activeOrTrialPlan.date} - ${activeOrTrialPlan.name}` }}
            <v-tooltip bottom v-if="activeOrTrialPlan.isInTrial">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="warning"
                  v-bind="attrs"
                  v-on="on"
                  v-text="'mdi-alert-octagon'"
                  size="20"
                  class="ml-1"
                />
              </template>
              <span
                >O período de teste (trial) deste plano está prestes a
                expirar</span
              >
            </v-tooltip>
            <v-tooltip bottom v-if="activeOrTrialPlan.trialExpiresToday">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="warning"
                  v-bind="attrs"
                  v-on="on"
                  v-text="'mdi-alert-octagon'"
                  size="20"
                  class="ml-1"
                />
              </template>
              <span>O período de teste (trial) deste plano expira hoje</span>
            </v-tooltip>
            <v-tooltip bottom v-if="activeOrTrialPlan.trialExpired">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="error darken-1"
                  v-bind="attrs"
                  v-on="on"
                  v-text="'mdi-alert-octagon'"
                  size="20"
                  class="ml-1"
                />
              </template>
              <span>O período de teste (trial) deste plano expirou</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.plans`]="{ item }">
          <div v-for="plan in item.plans" :key="plan.key" class="">
            {{ plan.plan.name }}
          </div>
        </template>

        <template v-slot:[`item.tag`]="{ item }">
          <v-chip
            small
            label
            outlined
            v-for="tag in item.tag"
            :key="tag + Math.random()"
            class="ma-1"
          >
            {{ tag }}
          </v-chip>
        </template>

        <template v-slot:header.data-table-expand>
          <CustomerTableColsSelector @change:table-cols="changeColumnsView" />
        </template>

        <template v-slot:[`item.data-table-expand`]="{ item }">
          <v-btn
            icon
            color="secondary lighten-1"
            :to="{
              path: '/reseller/client',
              query: {
                domain: item.main_domain,
                key: item.key,
              },
            }"
          >
            <v-icon v-text="'mdi-chevron-right'" />
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <Snackbar
      :show="showSnackBarSuccess"
      :message="successMessages.saves_changes"
      @close="showSnackBarSuccess = false"
    />

    <Snackbar
      :show="showSnackBarFailure"
      color="error"
      :message="errorMessages.unknown"
      @close="showSnackBarFailure = false"
    />
  </v-container>
</template>

<script>
import { headerClientesTable } from "@/helpers/variables/clients";
import {
  setSearchByString,
  updateAppiledFiltersValues,
} from "@/helpers/services/filters";
import {
  addYears,
  formatDate,
  formatDateAndHour,
  maskCNPJ,
  checkExpiresToday,
  formatDateFullMonth,
} from "@/helpers/services/utils";
import {
  translateResellerTypes,
  planPeriodic,
} from "@/helpers/variables/translateString";
import { STATUS, SUBSCRIPTIONS } from "@/helpers/variables/backendConstants";
import { paging, tableHeight } from "@/helpers/variables/tablesDefault";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import { getPaginationDataFromApi } from "@/helpers/services/users";

import { mapGetters, mapActions, mapMutations } from "vuex";

import SearchFilterBar from "@/components/general/SearchFilterBar";
import CustomerTableColsSelector from "@/components/reseller/CustomerTableColsSelector";
import CustomerPlan from "@/components/reseller/tables/cells/CustomerPlan";

import CustomerPlanSubscription from "@/components/reseller/tables/cells/CustomerPlanSubscription";

export default {
  name: "CustomersTable",

  components: {
    CustomerPlan,
    SearchFilterBar,
    CustomerTableColsSelector,
    CustomerPlanSubscription,
  },
  data() {
    return {
      errorMessages,
      successMessages,
      tableHeight,
      planPeriodic,
      STATUS,
      SUBSCRIPTIONS,
      changeTableHeaders: false,
      expanded: [],
      newExpanded: [],
      client: false,
      status: "",
      itemsPerPage: paging.perPage,
      itemsOptions: [...paging.All, -1],
      items: [],
      showSnackBarFailure: false,
      showSnackBarSuccess: false,
      loadingClients: false,
      translateResellerTypes,
      pagingListClients: [],
      count: 0,
      pagination: {
        sortBy: ["created_at"],
        page: 1,
        sortDesc: [true],
        itemsPerPage: paging.perPage,
      },
      currentFilters: [],
      lastQuery: null,
      headersToShow: [],
      loadBtnDownload: false,
    };
  },

  computed: {
    ...mapGetters(["company", "token", "isConecta", "clientsCanceledResquest"]),

    appliedFiltersToDisplay() {
      const filtersToSearch = ["name", "main_domain", "domains_list"];

      return this.currentFilters.filter(
        (item) =>
          !filtersToSearch.includes(item.value) &&
          item.search !== "" &&
          item.search !== null
      );
    },

    currentOffset() {
      const { page, itemsPerPage } = this.pagination;
      return (page - 1) * itemsPerPage;
    },

    headers: {
      get() {
        const tableCols =
          this.headersToShow ||
          JSON.parse(localStorage.getItem("customersTableCols")) ||
          headerClientesTable;
        return tableCols.filter((item) => item.show);
      },
      set(newValues) {
        this.headersToShow = newValues;
      },
    },
  },

  watch: {
    pagination: {
      handler() {
        this.fetchClients();
      },
      deep: true,
    },

    clientsCanceledResquest() {
      if (this.clientsCanceledResquest) {
        this.showLoading = true;
      }
    },
  },

  methods: {
    ...mapActions(["getResellers", "fetchClientsPage", "getClients"]),
    ...mapMutations(["setClientView", "setIsHomePage", "setClientsSize"]),

    addYears,

    formatDate,

    formatDateAndHour,

    maskCNPJ,

    checkExpiresToday,

    getPaginationDataFromApi,

    formatDateFullMonth,

    filterCompanyPlans(companyPlans) {
      return this.isConecta
        ? companyPlans
        : companyPlans.filter(
            (plan) => plan.subscription_type === SUBSCRIPTIONS.ONCE
          );
    },

    setSearch(search_term) {
      const filtersToSearch = ["name", "main_domain", "domains_list"];

      let formatedSearch = [];

      if (search_term) {
        formatedSearch = setSearchByString(search_term, filtersToSearch);
        const currentFilters = this.currentFilters.filter(
          ({ value }) => !filtersToSearch.includes(value)
        );
        formatedSearch = [...currentFilters, ...formatedSearch];
        this.updateFilters(formatedSearch);
      } else {
        this.clearTextSearch();
      }
    },

    clearTextSearch() {
      const filtersToSearch = ["name", "main_domain", "domains_list"];
      this.currentFilters = this.currentFilters.filter(
        ({ value }) => !filtersToSearch.includes(value)
      );

      this.updateFilters(this.currentFilters);
    },

    async fetchClients() {
      this.loadingClients = true;
      const queryParams = this.getPaginationDataFromApi(
        this.pagination,
        this.currentFilters.length > 0,
        this.currentFilters,
        this.currentOffset
      );
      if (JSON.stringify(queryParams) != JSON.stringify(this.lastQuery)) {
        this.lastQuery = queryParams;
        await this.fetchClientsPage(queryParams)
          .then(({ data }) => {
            this.pagingListClients = data.clients;
            this.count = data.count;
            this.setClientsSize(data.count);
          })
          .catch((e) => {
            console.error("Erro Paginação: ", e);
          });
      }
      this.loadingClients = false;
    },

    findThisReseller(domain) {
      const reseller = this.pagingListClients.find(
        (company) => company.company.main_domain === domain
      );

      return reseller.company.key;
    },

    goToReseller(domain) {
      let key = this.findThisReseller(domain);
      if (key) {
        this.$router.push(`/reseller/client?domain=${domain}&key=${key}`);
      }
    },

    changeColumnsView(newCols) {
      this.headers = newCols.slice().filter((item) => item.show);
    },

    goToClientPlan(item, plan_key) {
      this.$router.push({
        path: "/reseller/client",
        query: { domain: item.main_domain, key: item.key, plan: plan_key },
      });
    },

    newPlan(item) {
      this.$router.push({
        path: "/reseller/new-plan",
        query: { domain: item.main_domain, key: item.key },
      });
    },

    getPlansInTrialAndActive(plans) {
      plans = plans.slice();
      plans.sort((a, b) => new Date(b.end_trial) - new Date(a.end_trial));

      const validPlans = plans.reduce((acc, plan) => {
        const planInTrial = plan.status === STATUS.TRIAL;
        const isActive = plan.status === STATUS.ACTIVE;
        let color = "";

        if (planInTrial || isActive) {
          const trialExpired = plan.valid_status === STATUS.TRIAL_EXPIRED;
          const isInTrial = plan.valid_status === STATUS.TRIAL;
          const trialExpiresToday =
            checkExpiresToday(plan.end_trial) && planInTrial;

          if (isInTrial) {
            color = "orange--text text--darken-2";
          } else if (trialExpired) {
            color = "red--text text--darken-3 ";
          } else if (trialExpiresToday) {
            color = "orange--text text--darken-2";
          }

          acc.push({
            date: this.formatDateFullMonth(plan.end_trial),
            name: plan.plan.name,
            color,
            isInTrial,
            trialExpired,
            trialExpiresToday,
            isActive,
          });
        }

        return acc;
      }, []);

      return validPlans;
    },

    getTypeOfPlans(plans) {
      let annual = 0;
      let mensal = 0;
      let trimestral = 0;

      plans.forEach((plan) => {
        if (plan.subscription_type === SUBSCRIPTIONS.ANNUAL) {
          annual++;
        } else if (
          plan.subscription_type === SUBSCRIPTIONS.FLEX &&
          plan.status !== STATUS.TRIAL
        ) {
          mensal++;
        } else if (plan.subscription_type === SUBSCRIPTIONS.QUARTELY) {
          trimestral++;
        }
      });

      let total = [];
      total[0] = annual;
      total[1] = mensal;
      total[2] = trimestral;

      return total;
    },

    getStatusOfPlans(plans) {
      let activated = 0;
      let disabled = 0;
      let trial = 0;

      plans.forEach((plan) => {
        if (plan.status === "ACTIVE") {
          activated++;
        } else if (plan.status === "DISABLED") {
          disabled++;
        } else if (plan.status === "TRIAL") {
          trial++;
        }
      });

      let total = [];
      total[0] = activated;
      total[1] = disabled;
      total[2] = trial;

      return total;
    },

    removeFilter(filter) {
      const index = this.currentFilters.findIndex(
        (item) => item.search === filter.search
      );
      this.currentFilters.splice(index, 1);

      this.updateFilters(this.currentFilters);
    },

    updateFilters(value) {
      this.currentFilters = updateAppiledFiltersValues(
        this.currentFilters,
        value,
        ["name", "main_domain", "domains_list"]
      );

      this.pagination.page = 1;
      this.fetchClients();
    },

    updateTableCols() {
      const localStorageHeaders =
        JSON.parse(localStorage.getItem("customersTableCols")) || null;

      if (
        !localStorageHeaders ||
        !localStorageHeaders.find((item) => item.value === "plan")
      ) {
        localStorage.setItem(
          "customersTableCols",
          JSON.stringify(headerClientesTable)
        );
      }

      const headers = JSON.parse(localStorage.getItem("customersTableCols"));

      this.headers = headers;
    },
  },

  async beforeMount() {
    this.loadingClients = true;

    this.updateTableCols();

    this.setClientView(false);
    this.loadingClients = false;
  },
};
</script>
<style scoped>
.disabled-item {
  background-color: #f5f5f5;
}
.v-list-item .disabled-item {
  border-bottom: 1px solid #e0e0e0 !important;
}
.v-list-item svg,
.v-list-item i,
.v-list-item button {
  display: none;
}
.v-list-item:hover svg {
  cursor: move;
  display: block;
}
.v-list-item:hover button {
  display: block;
  cursor: pointer;
}
.v-list-item:hover i {
  display: block;
}
</style>
