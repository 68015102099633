<template>
  <v-tooltip bottom v-if="subscriptionType">
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        outlined
        class="pr-3 py-1 font-weight-medium"
        :color="isExpired ? 'error' : statusColor[currentStatus]"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          v-text="isExpired ? 'mdi-alert-circle' : 'mdi-check-circle'"
          left
          size="18"
        />
        {{ planPeriodic[subscriptionType] }}
        ({{ statusLabel.toLowerCase() }})
      </v-chip>
    </template>
    <span v-if="isExpired"> Plano expirou em {{ formatedTrialDate }} </span>
    <span v-else-if="isTrial"> {{ trialTooltipLabel }} </span>
    <span v-else-if="company.suspended"> {{ suspendedLabel }} </span>
    <span v-else> Plano ativo desde {{ formatDateFullMonth(initDate) }} </span>
  </v-tooltip>
</template>
<script>
import { status, planPeriodic } from "@/helpers/variables/translateString";
import { statusColor } from "@/helpers/variables/colors";
import { STATUS, SUBSCRIPTIONS } from "@/helpers/variables/backendConstants.js";
import {
  /* 
  
  checkExpiresToday, */
  checkExpiredDate,
  formatDateFullMonth,
  getExpiredTrialDays,
  getRemainingTrialDays,
} from "@/helpers/services/utils";

export default {
  name: "CustomerPlanSubscription",

  props: {
    company: { type: Object, required: true },
  },

  data() {
    return {
      planPeriodic,
      status,
      statusColor,
    };
  },

  computed: {
    suspendedLabel() {
      const { suspended } = this.company;

      if (!suspended) return "";

      return `Plano suspenso desde ${formatDateFullMonth(suspended)}`;
    },

    currentStatus() {
      return this.company?.current_plan?.valid_status;
    },

    formatedTrialDate() {
      return formatDateFullMonth(this.trialExpiration);
    },

    initDate() {
      return this.company?.current_plan?.init_date;
    },

    isExpired() {
      return this.isTrial && checkExpiredDate(this.trialExpiration);
    },

    isTrial() {
      return (
        this.currentStatus === STATUS.TRIAL &&
        this.subscriptionType === SUBSCRIPTIONS.TRIAL
      );
    },

    remainingTrialDays() {
      return getRemainingTrialDays(this.trialExpiration) || "Não informado";
    },

    subscriptionType() {
      return this.company?.current_plan?.subscription_type;
    },

    trialExpiration() {
      return this.company?.current_plan?.end_trial;
    },

    trialTooltipLabel() {
      if (this.isExpired) {
        return `${getExpiredTrialDays(this.trialExpiration)}`;
      } else if (this.isTrial) {
        return `Expira em ${this.formatedTrialDate} (${this.remainingTrialDays})`;
      } else {
        return "";
      }
    },

    statusLabel() {
      if (this.isExpired) {
        return "Expirado";
      } else if (this.isTrial) {
        return "Ativo";
      } else {
        return this.status[this.currentStatus];
      }
    },
  },

  methods: {
    formatDateFullMonth,
  },
};
</script>
