<template>
  <div>
    <CustomersTable>
      <template #header>
        <v-row no-gutters class="px-3 pt-3 ma-0" align="center">
          <v-col cols="12" xs="12" sm="5">
            <h2 style="font-size: 1.5rem" class="font-weight-regular">
              Clientes
            </h2>
          </v-col>

          <v-col
            cols="12"
            xs="12"
            sm="7"
            :class="`d-flex ${
              $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-start'
            }`"
          >
            <DownloadCustomersButton />
          </v-col>
        </v-row>
      </template>
    </CustomersTable>
  </div>
</template>

<script>
import CustomersTable from "@/components/reseller/tables/CustomersTable.vue";
import DownloadCustomersButton from "@/components/reseller/buttons/DownloadCustomersButton";
export default {
  name: "Customers",

  components: {
    CustomersTable,
    DownloadCustomersButton,
  },
};
</script>

<style></style>
