<template>
  <v-list-item class="pa-0 ma-0" v-if="currentPlan">
    <v-list-item-content class="pa-0">
      <v-list-item-title
        class="d-flex align-center flex-wrap font-weight-medium mb-1 text-body-1 secondary--text"
      >
        {{ currentPlan.name }}
      </v-list-item-title>
      <v-list-item-subtitle class="text-body-2">
        <span class="font-weight-medium">Encerramento do Trial:</span>
        {{ formatDateFullMonth(trialExpiration) }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
  <span v-else class="secondary--text text--lighten-1"> Nenhum plano </span>
</template>
<script>
import { mapGetters } from "vuex";
import { STATUS } from "@/helpers/variables/backendConstants.js";
import { statusColor } from "@/helpers/variables/colors";
import { planPeriodic, status } from "@/helpers/variables/translateString";

import {
  /* 
  formatDateFullMonth,
  checkExpiresToday, */
  checkIfIsGoingToExpire,
  isExpiredTrial,
  formatDateFullMonth,
} from "@/helpers/services/utils";

export default {
  name: "CustomerPlanCell",

  props: {
    company: { type: Object, required: true },
  },

  data() {
    return {
      planPeriodic,
      status,
      statusColor,
    };
  },

  computed: {
    ...mapGetters([""]),

    currentCompanyPlan() {
      return this.company?.current_plan;
    },

    currentPlan() {
      return this.company?.current_plan.plan;
    },

    currentStatus() {
      return this.isTrial
        ? isExpiredTrial(this.trialExpiration)
        : this.currentCompanyPlan.valid_status;
    },

    isTrial() {
      return this.currentCompanyPlan.valid_status === STATUS.TRIAL;
    },

    isExpired() {
      return this.isTrial && !checkIfIsGoingToExpire(this.trialExpiration);
    },

    subscriptionType() {
      return this.currentCompanyPlan?.subscription_type;
    },

    trialExpiration() {
      return this.currentCompanyPlan.end_trial;
    },
  },

  methods: {
    formatDateFullMonth,
  },
};
</script>
