<template>
  <download-csv
    class="pa-0"
    :data-requester="getListOfClients"
    :labels="headersTitles"
    :fields="fields"
    primary-name="clientes"
    @export-started="loading = true"
    @export-finished="loading = false"
  >
    <v-btn
      text
      large
      :block="$vuetify.breakpoint.xs"
      color="accent"
      :class="`text-none text-subtitle-1 font-weight-bold ${
        isMobile ? 'px-0 mt-1' : ''
      }`"
      :loading="loading"
    >
      <svg style="width: 24px; height: 24px" viewBox="0 0 24 24" class="mr-2">
        <path
          fill="currentColor"
          d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z"
        />
      </svg>
      Fazer download dos clientes
    </v-btn>
  </download-csv>
</template>
<script>
import { mapActions } from "vuex";

import { headersTitles } from "@/helpers/variables/tablesDefault";

import JsonCSV from "@/components/main/shared/download";

export default {
  name: "DownloadCustomersButton",

  components: { "download-csv": JsonCSV },

  data() {
    return {
      fields: [
        "id_google",
        "name",
        "main_domain",
        "created_at",
        "users_number",
        "is_reseller",
        "responsible_name",
        "responsible_email",
        "financial_email",
        "phone",
        "verified_phone",
        "cnpj",
        "address_postal_code",
        "address_info",
        "plan_name",
        "plan_sku",
        "plan_status",
        "plan_licenses",
        "plan_max_licenses",
        "plan_end_trial",
        "plan_end_date",
        "plan_subscription_type",
        "plan_price_per_period",
        "plan_price_period_per_month",
        "plan_price",
        "company_plan_discount",
        "plan_next_billing",
        "contract",
        "reseller_name",
        "reseller_domain",
        "reseller_type",
        "reseller_commission",
        "reseller_discount",
        "tags",
      ],
      headersTitles,
      loading: false,
      clientsByCompanyPlan: [],
    };
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  methods: {
    ...mapActions(["getClients"]),

    async getListOfClients() {
      this.loading = true;
      return await this.getClients().finally(() => (this.loading = false));
    },
  },
};
</script>
